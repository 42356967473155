// tailwindcss
import './src/styles/global.css';
import ReactDOM from 'react-dom';
import { loadableReady } from '@loadable/component';

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}

// Updating Gatsby's default scroll behavior
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.pathname === '/') {
    window.scrollTo(0, 0);
    return false; // false to prevent Gatsby's default scroll behavior
  }

  const savedPosition = getSavedScrollPosition(location);
  window.scrollTo(...(savedPosition || [0, 0]));

  return false; // false to prevent Gatsby's default scroll behavior
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}
